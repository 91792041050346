import React from "react"
import { graphql } from "gatsby"
import Nav from "../components/UI/Nav/Nav"
import Footer from "../components/UI/Footer/Footer"
import { Container, Row, Col, Image } from "react-bootstrap"
import SEO from "../components/seo"

import "../assets/css/us.css"

const Us = ({ data }) => {
  return (
    <Container className="no-gutters" fluid>
      <SEO />
      <Nav active="NOSOTROS" />
      <Row className="justify-content-center">
        {data.prismic.allNosotross.edges.map(item => (
          <Col
            xs="11"
            sm="6"
            md="3"
            className="d-flex flex-column justify-content-center align-items-center profile-us my-4"
          >
            <Image
              alt="person"
              src={item.node.imageSharp.childImageSharp.fluid.src}
              roundedCircle
            />
            <h4>{item.node.nombre.map(name => name.text)}</h4>
            <p>{item.node.posicion.map(position => position.text)}</p>
          </Col>
        ))}
      </Row>
      <Row className="justify-content-center video-container py-5">
        <Col xs="12" md="10" lg="6" className="d-flex justify-content-center">
          <div
            style={{
              padding: "56.25% 0 0 0",
              position: "relative",
              width: "100%",
              maxWidth: "850px",
            }}
          >
            <iframe
              title="vimeo-video"
              src="https://player.vimeo.com/video/421815466?title=0&byline=0&portrait=0"
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
              }}
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </Col>
      </Row>
      <Footer />
    </Container>
  )
}

export const usQuery = graphql`
  query usQueryAndImageQuery {
    prismic {
      allNosotross(sortBy: orden_DESC) {
        edges {
          node {
            nombre
            posicion
            image
            imageSharp {
              childImageSharp {
                fluid(maxWidth: 250, maxHeight: 250, quality: 100) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`
export default Us
